import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { A, Div, H4, Button, H5, Img, P, Select, Col, Span } from '../common/Elements';
import { Row } from 'react-bootstrap';

import ModalTooltip from './ModalTooltip';
import {
  PlansData,
  Plan,
  PlansDetailSection,
  ComparePlansProps,
  SelectedPlanDetail,
} from './fragments/plans.interface';
import ComparePricingMobileTabMenu from './ComparePricingMobileTabMenu';
import FeaturesIcons from './FeaturesIcons';
import {
  CERTIFICATEMANAGEMENT,
  COMPLIANCE,
  CUSTOMIZATION,
  DASHBOARD,
  FIREWALL,
  INSIGHT,
  PERFORMANCE,
  RELIABILITY,
  SECURITY,
  SUPPORT,
} from './constants';
import { getPlanDetailsSection } from './formatPlans';
import MediaContainer, { Size } from '../common/MediaContainer';
import { Icon } from '../common/Icon';

const PlanDetailSectionOptions = (plansData?: PlansData) => {
  const options = [
    {
      localized: plansData?.plansDetailSectionTitlePerformance,
      english: PERFORMANCE,
    },
    { localized: plansData?.plansDetailSectionTitleCustomization, english: CUSTOMIZATION },
    { localized: plansData?.plansDetailSectionTitleSecurity, english: SECURITY },
    {
      localized: plansData?.plansDetailSectionTitleCertificateManage,
      english: CERTIFICATEMANAGEMENT,
    },
    { localized: plansData?.plansDetailSectionTitleFirewall, english: FIREWALL },
    { localized: plansData?.plansDetailSectionTitleReliability, english: RELIABILITY },
    { localized: plansData?.plansDetailSectionTitleDashboard, english: DASHBOARD },
    { localized: plansData?.plansDetailSectionTitleInsights, english: INSIGHT },
    { localized: plansData?.plansDetailSectionTitleCompliance, english: COMPLIANCE },
    { localized: plansData?.plansDetailSectionTitleSupport, english: SUPPORT },
  ];

  return options.map(option => (
    <option key={option.english} value={option.localized} data-english-key={option.english}>
      {option.localized}
    </option>
  ));
};

const getSelectedOptionDataAttribute = (
  selectRef: React.RefObject<HTMLSelectElement>
): SelectedPlanDetail | null => {
  const selectElement = selectRef.current;
  const refNode = ReactDOM.findDOMNode(selectElement);

  const optionElement = refNode?.childNodes[
    selectElement?.selectedIndex as number
  ] as HTMLOptionElement;

  return {
    english: optionElement.getAttribute('data-english-key') as string,
    localized: optionElement.value,
  };
};

function handlePlanDetailSectionChange(
  event: React.ChangeEvent<HTMLSelectElement>,
  setSelectedSection?: React.Dispatch<React.SetStateAction<SelectedPlanDetail | undefined>>
) {
  const selectedOption = (event.target.childNodes[event.target.selectedIndex] as any).getAttribute(
    'data-english-key'
  );
  if (setSelectedSection) {
    setSelectedSection({
      english: selectedOption,
      localized: event.target.value,
    });
  }
}
const ComparePlans: React.FC<ComparePlansProps> = ({
  plansData,
  selectedPlan,
  setSelectedPlan,
}) => {
  const [selectedTooltip, toggleSelectedTooltip] = useState('');
  const selectRef = useRef<HTMLSelectElement>(null);
  const [selectedPlanDetailSection, setSelectedPlanDetailSection] = useState<
    SelectedPlanDetail | undefined
  >({
    english: plansData?.plansDetailSectionTitlePerformance,
    localized: plansData?.plansDetailSectionTitlePerformance,
  });
  const sections = getPlanDetailsSection(plansData, selectedPlanDetailSection as any) || [];

  useEffect(() => {
    setSelectedPlanDetailSection(getSelectedOptionDataAttribute(selectRef) as any);
  }, [plansData]);
  return (
    <MediaContainer>
      {(size: Size) => (
        <Div color="black">
          <Row
            noGutters={true}
            style={{
              top:
                (!size.xs && size.sm && size.md && !size.lg) ||
                (size.xs && !size.md) ||
                (size.sm && !size.md)
                  ? 70
                  : 98,
              position: 'sticky',
              zIndex: 2,
              backgroundColor: 'white',
              paddingBottom: (size.xs && !size.md) || (size.sm && !size.md) ? 0 : '16px',
              paddingTop: '20px',
            }}
          >
            <ComparePricingMobileTabMenu
              plans={plansData?.plans}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              size={size}
            />
            <Col lg={3} md={4} marginRight={size.md ? 4 : 0}>
              <Div
                display="flex"
                flexDirection="column"
                justifyContent="end"
                style={{ height: '100%' }}
              >
                <Div style={{ paddingRight: size.md ? '35px' : 0 }}>
                  <P fontSize={2} fontWeight={6} lineHeight="copy" margin={0} marginVertical={1}>
                    {plansData?.plansDetailFeatureBreakdownText}
                  </P>
                  <Div position="relative">
                    <select
                      className={`native-dropdown absolute body-2 h-100 f2 w-100 z-1 o-0`}
                      value={selectedPlanDetailSection?.english}
                      onChange={e => handlePlanDetailSectionChange(e, setSelectedPlanDetailSection)}
                      ref={selectRef}
                    >
                      {PlanDetailSectionOptions(plansData)}
                    </select>
                    <Div
                      padding={1}
                      border="all"
                      borderColor={'gray1'}
                      display="flex"
                      zIndex={2}
                      flex={'auto'}
                      position="relative"
                      lineHeight={1}
                      className={`pe-none`}
                      role="dropdown-facade"
                    >
                      <Span flex={'auto'} className={'body-2 pe-none'} lineHeight="copy">
                        {selectedPlanDetailSection?.english}
                      </Span>
                      <Span display="flex" alignItems="center">
                        <Icon type="select-dropdown-caret" fill={'gray1'} />
                      </Span>
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Col>
            <Div
              display={['block', 'none', 'block', 'none']}
              className="divider"
              color="gray2"
              border="top"
              marginTop={3}
              marginBottom={2}
              width="100%"
            ></Div>

            {plansData?.plans.map((plan: Plan) => (
              <Col
                lg={2}
                md={3}
                key={plan.displayName}
                display={(size.sm && !size.md) || size.xs ? 'none' : 'flex'}
              >
                <Div>
                  <H4 color="orange0" marginBottom={1}>
                    {plan.displayName}
                  </H4>
                  <H5 margin={0} fontWeight={6} marginBottom={3} className="nowrap">
                    {plan?.priceCurrencySymbol === null
                      ? plan?.price
                      : `${plan?.priceCurrencySymbol}${plan?.price}/${plan?.priceDurationPeriod}`}
                  </H5>

                  <A
                    fontWeight={7}
                    backgroundColor="orange1"
                    color="white"
                    border="none"
                    paddingVertical={2}
                    paddingHorizontal={2}
                    className="pointer dim nowrap"
                    textAlign="center"
                    fontSize={1}
                    marginTop={1}
                    href={plan?.ctaButtonUrl}
                    width="100%"
                    style={{ minWidth: '158px' }}
                  >
                    {plan?.ctaButtonText}
                  </A>
                </Div>
              </Col>
            ))}
          </Row>
          <Div
            className="divider"
            color="gray2"
            border="top"
            marginTop={5}
            width="100%"
            display={(size.sm && !size.md) || size.xs ? 'none' : 'flex'}
          ></Div>
          <Div marginBottom={size.md ? 10 : 0}>
            {(sections as any)?.map((section: PlansDetailSection) => (
              <Row className="b--gray2 bb" noGutters key={section.contentfulId}>
                <Col lg={3} md={12} marginRight={size.md ? 4 : 0}>
                  <Div
                    display={'flex'}
                    justifyContent={'between'}
                    alignItems="center"
                    paddingTop={[2, 3]}
                    paddingBottom={[1, 3]}
                    position="relative"
                  >
                    <P fontSize={2} fontWeight={4} margin={0} lineHeight="copy">
                      {section.displayName}
                    </P>
                    {section.toolTip && (
                      <Button
                        backgroundColor="transparent"
                        border="none"
                        style={{ cursor: 'pointer' }}
                        alignSelf="end"
                        display="flex"
                        marginRight={size.md ? 4 : 0}
                        onClick={() => {
                          if (selectedTooltip === section.displayName) {
                            toggleSelectedTooltip('');
                          } else {
                            toggleSelectedTooltip(section.displayName);
                          }
                        }}
                      >
                        <Icon type="info" />
                      </Button>
                    )}
                    {selectedTooltip === section.displayName && (
                      <ModalTooltip
                        section={section}
                        toggleTooltip={toggleSelectedTooltip}
                        size={size}
                      />
                    )}
                  </Div>
                </Col>
                <FeaturesIcons section={section} size={size} selectedPlan={selectedPlan} />
              </Row>
            ))}
          </Div>
        </Div>
      )}
    </MediaContainer>
  );
};

export default ComparePlans;
